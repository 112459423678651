.countdown-timer {
    display: flex;
    justify-content: center; /* Center the timer items horizontally */
    color: #59442e;
}

.timer-item {
    flex: 2; /* Prevent items from shrinking */
    border: 5px double #59442e; /* Set border color to white */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px; /* Add space between boxes */
    padding: 5px 10px; /* Add padding for better spacing */
}

.timer-value {
    font-size: 50px; /* Adjust font size as needed */
}

.timer-label {
    font-size: 23px; /* Adjust font size as needed */
}

/* Responsive adjustments */
@media screen and (max-width: 420px) {
    .timer-value {
        font-size: 25px; /* Decrease font size for smaller screens */
    }

    .timer-label {
        font-size: 12px; /* Decrease font size for smaller screens */
    }

    .timer-item {
        flex-basis: 100%; /* Set each item to take up full width on smaller screens */
        margin: 0px 3px; /* Adjust margin for better spacing */
    }
}

.App {
  /*max-width: 105rem;*/
  /*margin: 0 auto;*/
  font-family: "Cormorant Garamond", serif;
  /*font-family: "Cormorant Garamond", sans-serif;*/
}

.decor1{
  width: 15%;
  margin-bottom: 0.7rem ;
}

.decor2{
  width: 20%;
  margin-top: 0.5rem;
}


/*VIRAGOK*/

.decor{
  height: 28vw;
}

.decor3{
  height: 28vw;
  position: absolute;
  right: 0;
}


.decor4{
  position: relative;
  left: 0;
  rotate: 180deg;
  bottom: 250px;
}

.decor5{
  position: absolute;
  left: 0;
  rotate: 270deg;
}

.decor6{
  position: absolute;
  right: 0;
  rotate: 90deg;
  bottom: -80px;
}
/*VIRAGOK*/

.handwriting{
  font-family: "Great Vibes", serif;
  font-size: 4rem;
}

.generic-text{
  /*font-family: SansSerif, serif;*/
}

#kezdolap{
  padding-top: 3rem;
  /*height: 100vh;*/
  /*background-color: lightblue;*/
  text-align: center;
  color: white;

}

#kezdolap-content{
  margin: 0 auto;
  height: 44rem;
  /*max-width: 60rem;*/
  background-size: cover;
  /*background-size: cover; !* Cover the entire div *!*/
  background-position: center; /* Center the image */
  /*background-position: top center; !* Ensure the top of the image is always in view *!*/
  background-repeat: no-repeat;
}

#kezdolap-content-text{
  position: relative;
  top: 25rem;
  font-size: 2rem;
  padding: 0 2rem;
}



.segment-title{
  padding: 1.3rem;
  font-size: 4rem !important;
  text-align: center;
}

#datum{
  position: relative;
  bottom: 3rem;
}

#datum-outer {
  color: white;
}

#datum-wrapper{
  text-align: center;
  margin: 0 auto;
  background-size: cover;
  background-position: top center; /* Center the image */
  background-repeat: no-repeat;
}

.datum-content{

}

.date-text{
  margin-bottom: 2rem;
  color: black;
}

.date-generic{
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.line-container{
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 2rem;
  padding: 0 1rem;
}

.horizontal-line {
  flex: 1; /* Take up remaining space */
  border-bottom: 1px solid black; /* Adjust the color and thickness as needed */
}

.actual-date{
  font-size: 2.2rem;
  font-style: italic;
  margin: 0 auto;
}

.date-generic2{
  margin-top: 1rem;
  font-size: 1.2rem;
}

.datum-utani-kep{
  margin-top: -253px;
  height: 24rem;
  background-size: cover;
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
}

#helyszin{
  position: relative;
  bottom: 3rem;
}

.andsign{
  margin-bottom: -1rem;
}

.helyszin-es-tudnivalok{
  position: relative;
  display: flex;
  gap: 2.5rem;
  justify-content: space-evenly;
  margin: 0 auto;
  padding: 0 3rem;
}

.helyszin-es-tudnivalok::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 30px;
  left: 50%;
  border-left: 1px solid rgba(90, 90, 90, 0.56); /* Adjust color and thickness as needed */
}

.helyszin-container {
  width: 20rem; /* Increased width for better readability */
  margin-bottom: 2rem; /* Added margin bottom for spacing between containers */
}

.helyszin-title {
  font-family: "Cormorant Garamond", serif;
  font-size: 2.3rem; /* Increased font size for title */
  margin-bottom: 0.5rem; /* Added margin bottom for spacing between title and text */
  text-align: center;
}

.googleForm {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  color: black;
}

.googleForm a{
  text-decoration: none;
  border-width: 1px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f2a83d;
  transition: background-color 0.3s;
}

.googleForm a:hover {
  background-color: #a2dfa2; /* Change 'yellow' to your desired hover color */
}

.googleForm a:visited {
  color: inherit; /* This will set the color to be the same as the default link color */
  text-decoration: none; /* Ensure text decoration is consistent */
}

.googleForm a:link {
  color: black;
  text-decoration: none;
}

.googleForm a:active {
  color: black;
  text-decoration: none;
}

.kLetter{
  font-size: 2rem;
}

.helyszin-text {
  font-family: "Cormorant Garamond", serif; /* Changed font family for text */
  font-size: 1.5rem; /* Adjusted font size for text */
  line-height: 1.5; /* Increased line height for better readability */
  text-align: center;
}

.sub-title{
  text-decoration: underline;
}

.sub-text{
  margin-bottom: 0.5rem;
}

.helyszin-utani-kep{
  margin-top: 79px;
  height: 24rem;
  background-size: cover;
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
}

@media screen and (max-width: 1000px) {

  .decor4{
    bottom: 150px;
  }

  .datum-utani-kep{
    margin-top: -153px;
    height: 20rem;
  }
}

@media screen and (max-width: 650px) {
  .decor{
    height: 28vw;
  }

  .decor4{
    bottom: 77px;
  }

  .datum-utani-kep{
    margin-top: -80px;
  }

  .helyszin-es-tudnivalok{
    display: block;
    /*justify-content: space-evenly;*/
    margin: 0 auto;
  }

  .helyszin-es-tudnivalok::after {
    display: none;
  }

  .helyszin-container{
    margin: 0 auto 1.5rem auto;
    width: 70vw; /* Increased width for better readability */
  }

  /*.helyszin-container {*/
  /*  width: 20rem; !* Increased width for better readability *!*/
  /*  margin-bottom: 2rem; !* Added margin bottom for spacing between containers *!*/
  /*}*/
}

@media screen and (max-width: 420px) {

  #kezdolap-content {
    width: 100%;
    height: 25rem;
  }

  #kezdolap-content-text{
    top: 11rem;
    font-size: 1.5rem;
    padding: 0 2rem;
  }

  .handwriting{
    font-size: 2rem;
  }

  .generic-text{
    font-size: 1.2rem;
  }

  .decor1{width: 40%;
    margin-bottom: 0.7rem;
  }

  .line-container{
    gap: 1rem;
  }

  .datum-utani-kep{
    height: 16rem;
  }

  .helyszin-title {
    font-size: 1.5rem; /* Increased font size for title */
  }

  .googleForm {
    font-size: 1rem;
  }

  .helyszin-text {
    font-size: 1rem; /* Adjusted font size for text */
  }
}

